import React, {useState} from 'react';
import { useForm } from "react-hook-form";
import configData  from "../config.json";
import Store from "../store";


const Login  = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { register, handleSubmit, errors } = useForm();
    const [disabledBtn, setDisabledBtn] = useState(false);


    const adminLogin = (data) => {
      setDisabledBtn(true);

    return fetch(`${configData.SERVER_URL}/auth/admin/login`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "content-type": "application/json"
        },
        body: JSON.stringify({
          email: data.email,
          password: data.password,
        })
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          console.log(responseJson)
          if(responseJson.status === "success"){
            setDisabledBtn(false)
            console.log(responseJson)
            window.localStorage.setItem("Admin", responseJson.data.account.type);
            window.localStorage.setItem("token", responseJson.data.token)
            window.location = window.location.origin + "/Dashboard"  
          }
          if(responseJson.status === "error"){
            setDisabledBtn(false)
            alert(responseJson.message);
          }

        })
        .catch((error) => {
          setDisabledBtn(false)
          console.error(error);
        })
    }

  
    return (
        <>
        <div className="main">
            <div className="top-nav"></div>


            <section className="secTwo">
            <div class="container">
    <div class="row">
        <div class="col-md-3"></div>

        <div class="col-md-6 mt-5 mb-5">
                   <form onSubmit={handleSubmit(adminLogin)} class="text-center border border-light p-5">
                            <p class="h4 mb-4">Admin Login</p>       
                            <input type="email" name="email"  class="mb-4 p-2" placeholder="E-mail" required="" ref={register({ required: true})}/>
                             {errors.email && <p>Email Required</p>}

                            <input type="password" name="password" id="defaultLoginFormPassword" class="mb-4 p-2" placeholder="Password" required=""
                            ref={register({ required: true, })}/>
                            {errors.password && <p>Password Required</p>}

                            <div class="d-flex justify-content-around">
                                <div>
                                    <a href="">Forgot password?</a>
                                </div>
                             </div>

                            <button class="btn btn-warn btn-block btn-sm waves-effect waves-light mt-4" type="submit" disabled={disabledBtn}>
                                              {disabledBtn ? (<> Please Wait.. </>) : ( <> Login</>)}</button>
                        </form>
                    </div>
        <div class="col-md-3"></div>
    </div>
</div>
            </section>





        </div>

        </>

    );

}

export default Login;