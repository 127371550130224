import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import configData from '../../config.json';
import '../../assets/css/bootstrap.min.css';
import '../../assets/css/mdb.css';
import '../../assets/css/font-awesome.css';
import '../../assets/css/fontawesome/css/all.min.css';
import '../../assets/icofont/css/icofont.css';
import '../../assets/css/sidestyle.css';
import '../../assets/css/Adminsidebar.css'
import '../../assets/css/adminStyle.css'

const AddProduct = () => {
    const [product, setProduct] = useState([]);
    const [btnisloading, setBtnIsLoading] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [image, setImage] = useState();
    const [name, setName] = useState();
    const [id, setId] = useState('');
    const [costPerUnit, setCostPerUnit] = useState();
    const [unit, setUnit] = useState();

    const getProd = async () => {
    
        return fetch(`${configData.SERVER_URL}/products`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson);
              setProduct(responseJson.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }

    const addProd = (data) => {

        return fetch(`${configData.SERVER_URL}/products`, {
            method: "post",
            headers: {
              Accept: "application/json",
              "content-type": "application/json",
              "x-api-token":  window.localStorage.getItem("token")
            },
            body: JSON.stringify({
                name: data.name,
                unit:  data.unit,
                costPerUnit: data.costPerUnit
            })
          })
            .then((response) => response.json())
            .then(async (responseJson) => {
              console.log(responseJson)
              if(responseJson.status === "success"){
                console.log(responseJson)
                alert(responseJson.message);
              }
              if(responseJson.status === "error"){
                alert(responseJson.message);
              }
    
            })
            .catch((error) => {
            console.error(error);
            })
        }
    
    useEffect(()=>{
        getProd();
    }, [])

    const prodModal = (p) => {
      setId(p._id);
      setName(p.name);
      setUnit(p.unit);
      setCostPerUnit(p.costPerUnit);
      window.$("#productModal").modal("show") 
  }



  const updateProduct = (e) => {
          setBtnIsLoading(true);

          e.preventDefault()

          const form = new FormData(document.getElementById("submit"))

          return fetch(`${configData.SERVER_URL}/products/${id}/update`, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "x-api-token":  window.localStorage.getItem("token")
                },
                body:form
            })
            .then((response) => response.json())
            .then((responseJson) => {

              console.log(responseJson);
                if(responseJson.status === "success"){
                    setBtnIsLoading(false);
                    alert(responseJson.message);
                    console.log(responseJson.message);
                }
                if (responseJson.status === "error") {
                    setBtnIsLoading(false);
                    alert(responseJson.message);
                    console.log(responseJson.message);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return (
        
                <div class="row">
                    <div class="col-6">
                    <p  class="title2">Products</p> 
                    </div>
                    <div class="col-6">
                    <p class="title2">
                        <a href="" data-toggle="modal" data-target="#exampleModal"  style={{backgroundColor:"#ffd200", color:"#000", fontSize:"12px", padding:"5px 10px", borderRadius:"10px"}}> Add Product</a>
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Products</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                <div class="col-1"></div>
                                    <div class="col-10">
                                      <form onSubmit={handleSubmit(addProd)} class="">
                                        <div class="row">
                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="p-2" placeholder="Product Name" name="name" ref={register({ required: true, })}/>
                                                {errors.name && <p>Product name is required </p>}
                                            </div>
                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="Cost Per unit" name="costPerUnit" ref={register({ required: true, })}/>
                                                {errors.costPerUnit && <p>Product price is required</p>}
                                            </div>
                                            <div class="col-12">
                                                <div class="form-group">
                                                    <label for="exampleFormControlSelect1" className="mt-3">Select Unit</label>
                                                    <select class="form-control" name="unit" id="exampleFormControlSelect1" ref={register({ required: true, })}>
                                                      {errors.unit && <p>Product unit is required</p>}

                                                     {product.map(p => (
                                                        <option value={p.unit}>{p.unit}</option>

                                                      ))} 
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-warn my-4 btn-block waves-effect waves-light btn-sm" type="submit" name="add" disabled={btnisloading}>
                                              {btnisloading ? (<> Please Wait.. </>) : ( <> Add Product</>)}</button>
                                            </div>
                                        </div>
                                      </form>
                                    </div>
                                <div class="col-1"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                                                                        
                      </p>
                    </div>
                                
                    <div class="table-responsive">
                    <table class="table white black-text  table-hover">
                        <thead>
                        </thead>
                        <tbody>
                
                        {product.map(p => (
                            <tr class="">
                            <td><img src={`https://api.energie.agent01.online/images/products/${p.image}`} className="img-fluid" style={{width:'10%'}}/></td>
                            <td>{p.name}</td>
                            <td>{p.unit}</td>
                            <td># {p.costPerUnit}</td>
                            <td>
                             <td onClick={() => prodModal(p)}>
                             <i class="fas fa-pen" style={{color:"#000"}}></i>
                             </td>
                            </td>
                            <td><i class="fas fa-trash" style={{color:"red"}}></i></td>
                            </tr>
                        ))} 
                        </tbody>
                    </table>
                    </div>


                    <div class="modal fade" id="productModal" tabindex="-1" role="dialog" aria-labelledby="productModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="productModalLabel">Add Products</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                <div class="col-1"></div>
                                    <div class="col-10">



                                    <form onSubmit={updateProduct} enctype="multipart/form-data" class="row" id="submit">
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlFile1">Upload Product Image Here</label>
                                                <input type="file" class="form-control-file" name="image" id="exampleFormControlFile1"/>
                                            </div>
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlInput1">Product Name</label>
                                                <input type="text" class="p-1" value={name}  onChange={(e) => setName(e.target.value)}  name="name"/>
                                            </div>
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlInput1">Product Price</label>
                                                <input type="text" class="p-1" value={costPerUnit}  onChange={(e) => setCostPerUnit(e.target.value)}  name="costPerUnit"/>
                                            </div>
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlSelect1">Select Unit</label>
                                                <select class="form-control" value={unit} id="exampleFormControlSelect1" name="unit"  onChange={(e) => setUnit(e.target.value)}>
                                                
                                                {product.map(p => (
                                                        <option value={p.unit}>{p.unit}</option>
                                                      ))} 
                                                </select>
                                            </div>
                                            <button type="submit" class="btn btn-warn btn-block btn-sm waves-effect waves-light" name="submit" disabled={btnisloading}>
                                              {btnisloading ? (<> Please Wait.. </>) : ( <> Update</>)}
                                            </button>
                                            
                                            </form>
                                              
                                      
                                      
                                      
                                     </div>
                                <div class="col-1"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    
                    </div>




    );
  }

  export default AddProduct;