import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import {Link} from "react-router-dom";
import configData from '../config.json';
import { format } from 'date-fns';

const OilGas = ()=> {

  const [trans, setTrans] = useState([]);
  const [loading, setloading] = useState(true);

  const [show, setShow] = useState(false);

  const transFunc = async () => {
  
      return fetch(`${configData.TEST_URL}/energieRequestForm/oilDeal`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson.data);
            setTrans(responseJson.data);
            setloading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      const interval=setInterval(()=>{
          transFunc();
      },3000)
      
      return()=>clearInterval(interval)
      },[]);
      
     const [data, setData] = useState([])
      const openModal = (data) => {
        console.log(data)
        setData(data)
        setShow(true);

          window.$("#oilGasModal").modal("show") 
      };
    

    return (<>

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">OFFERS</p>
                                        </div>

                                                       
                                <div className="col-md-12 mt-2 mb-2">    
                                            <div className="row">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <ul className="breadcrumb">
                                                                   <li className="" style={{backgroundColor: "#ffd200", color: "#000"}}>
                                                                        <a href="">Active </a>
                                                                    </li>
                                                                      <li className="li"> 
                                                                         <a href="">Successful</a>
                                                                        </li>
                                                                      <li className="li"> 
                                                                         <a href="">Pending</a>
                                                                        </li>
                                                                        
                                                                </ul>
                                                            </div>
                                                            <div className="col-md-5 search">
                                                                <input className="form-control" type="text" placeholder="Search" aria-label="Search"/>
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                <div className="col-md-12 mt-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                          <p className="title2">All OFFERS</p> 
                                        </div>
                                        <div className="col-md-12">

               {loading ? <>
                       <p>loading</p> 
                       </> 
                          :
                                            <div className="row">
                                                <div className="col-md-11 mt-3">

                                                    <div className="table-responsive">
                                                      <table className="table white black-text  table-hover">
                                                        <thead>
                                                          </thead>
                                                          <tbody>

                                                    {trans.map(q => (
                                                        <tr onClick={()=> openModal(q)} className="">
                                                          <td>{q.q1}</td>
                                                          <td># {q.amount}</td>
                                                          <td>{format(new Date(q.createdAt), 'MMMM, do, yyy')}</td>
                                                        </tr>

                                                       ))} 

                                                          </tbody>
                                                      </table>
                                                    </div>
                                                </div>
                                            </div>
                         }
                                        </div>
                                    </div>
                                </div>




                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


      {/* modal */}

      <div class="modal fade" id="oilGasModal" tabindex="-1" role="dialog" aria-labelledby="oilGasModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="oilGasModalLabel">Offers Details</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                <div class="col-1"></div>
                                    <div class="col-10">

                                        <div classname="return mt-2">
                                            <h5>Q1. What products do you need?</h5>
                                            <p>{data.q1}</p>
                                        </div>
                                        <div classname="return mt-2">
                                            <h5>Q2. What quantity of products do you need?</h5>
                                            <p>{data.q2}</p>
                                        </div>
                                        <div classname="return mt-2">
                                            <h5>Q3. Where do you want the products to discharge at?</h5>
                                            <p>{data.q3}</p>
                                        </div>
                                        <div classname="return mt-2">
                                            <h5>Q4. How long do you have to give for delivery?</h5>
                                            <p>{data.q4}</p>
                                        </div>
                                        <div classname="return mt-2">
                                            <h5>Q5. How much are you paying for the products?</h5>
                                            <p>{data.amount}</p>
                                        </div>
                                        <div classname="return mt-2">
                                            <h5>Q5.How are you paying ?</h5>
                                            <p>{data.q5}</p>
                                        </div>
                                        <div classname="return mt-2">
                                            <h5>Q5.State?</h5>
                                            <p>{data.stateName}</p>
                                        </div>
                                        
                                      
                                      
                                     </div>
                                <div class="col-1"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>



   </> );
  

}

export default OilGas;
  