
import React, {Component} from 'react';
import Sidebar from './Sidebar';
import {Link} from "react-router-dom";
import logo from '../assets/img/logo2.png';

export class Profile extends Component {

    
  render() {
    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">MY PROFILE</p>
                                        </div>

                                 
                                        <div class="col-md-12 mt-4">
                                            <div class="row">
                                               <div className="col-md-5 card ml-lg-3">
                                                   <div className="row p-3">
                                                        <div className="co">
                                                            <img className="img-fluid d-block" src={logo} width="60"/>
                                                        </div>
                                                        <div className="co">
                                                            <h7 className='profile'>
                                                            Precious Vwarho</h7>
                                                            <p className=''>
                                                            07035814787</p>
                                                            </div>
                                                       <div className="col-md-12">
                                                           <div className="row">
                                                                <div className="col-md-6">
                                                                    <p className="text-center">
                                                                    preciousvwarho@gmail.com</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="text-center">+2347035814787</p>
                                                                </div>
                                                            </div>
                                                           <hr/>
                                                       </div>
                                                       <div className="col-md-12">
                                                           <div className="row">
                                                                <div className="col-md-6">
                                                                    <p className="">
                                                                    Update Profile</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="text-center">Change Password</p>
                                                                </div>
                                                            </div>
                                                         </div>
                                                    </div>
                                               </div>
                                               <div className="col-md-5 card ml-lg-4">
                                                   <div className="row p-3">
                                                                                   
                                                   <div className="col-md-12 mt-4">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <div className="cad card" id="cad1">
                                                                        <i className="fas fa-credit-card fa-lg" aria-hidden="true"></i>
                                                                        <p>No of Transactions</p>
                                                                        <span>150</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                    <div className="cad card">
                                                                        <i className="fas fa-mail-bulk fa-lg" aria-hidden="true"></i>
                                                                        <p>Messages</p>
                                                                        <span>15</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                   </div>                                                   
                                               </div>
                                               <div className="col-md-10 card ml-lg-4 mt-4">
                                                   <div className="row p-3">
                                                        <div class="col-md-12 mt-4">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                <p class="title2">MESSAGES</p> 
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="table-responsive">
                                                                        <table class="table white black-text  table-hover">
                                                                            <thead>
                                                                            </thead>
                                                                            <tbody>
                                                                                
                                                                                <tr class="">
                                                                                   <td>
                                                                                   <div class="custom-control custom-checkbox">
                                                                                   <input type="checkbox" class="custom-control-input" id="defaultUnchecked"/>
                                                                                   <label class="custom-control-label" for="defaultUnchecked"></label>
                                                                                   </div>
                                                                                       </td>
                                                                                       
                                                                                   <Link to="/Transaction-Detials">
                                                                                       <td>Product have not been delivered till date</td>
                                                                                       <td>2/6/2020</td>
                                                                                       <td>
                                                                                           <span class="">closed</span>
                                                                                       </td>
                                                                                       </Link>
                                                                                       
                                                                                   </tr>
                                                                                
                                                                             <tr class="">
                                                                                <td>
                                                                                <div class="custom-control custom-checkbox">
                                                                                <input type="checkbox" class="custom-control-input" id="defaultUnchecked2"/>
                                                                                <label class="custom-control-label" for="defaultUnchecked2"></label>
                                                                                </div>
                                                                                    </td>
                                                                                    
                                                                                <Link to="/Transaction-Detials">
                                                                                    <td>Product have not been delivered till date</td>
                                                                                    <td>2/6/2020</td>
                                                                                    <td>
                                                                                        <span class="">closed</span>
                                                                                    </td>
                                                                                    </Link>
                                                                                    
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                     </div>
                                                                 </div>
                                                            </div>
                                                        </div>
                                                   </div>                                                   
                                               </div>
                                               {/* <div className="col-md-1"></div> */}
                                            </div>
                                        </div>




                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );
  }

}