
import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import {Link,useParams} from "react-router-dom";
import logo from '../assets/img/logo2.png';
import configData from '../config.json';

const UserDetials = () => {

    let { id } = useParams();
    const [user, setUser] = useState([]);

    const userFunc = async () => {
    
        return fetch(`${configData.SERVER_URL}/users/${id}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson.data);
              setUser(responseJson.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }

  useEffect(()=>{
    userFunc();
}, [])

    
    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">USER DETAILS</p>
                                        </div>

                                 
                                        <div class="col-md-12 mt-4">
                                            <div class="row">
                                               <div className="col-md-5 card ml-lg-3">
                                                   <div className="row p-3">
                                                        <div className="co">
                                                            <img className="img-fluid d-block" src={logo} width="60"/>
                                                        </div>
                                                        <div className="co">
                                                            <h7 className='profile'>
                                                            {user.firstName} {user.lastName}</h7>
                                                            </div>
                                                       <div className="col-md-12">
                                                           <div className="row">
                                                                <div className="col-md-6">
                                                                    <p className="text-center">
                                                                    {user.email}</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="text-center">{user.phoneNumber}</p>
                                                                </div>
                                                            </div>
                                                           <hr/>
                                                       </div>
                                                       <div className="col-md-12">
                                                           <div className="row">
                                                                <div className="col-md-6">
                                                                    <p className="">
                                                                    Update Profile</p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="text-center">Change Password</p>
                                                                </div>
                                                            </div>
                                                         </div>
                                                    </div>
                                               </div>
                                               <div className="col-md-5 card ml-lg-4">
                                                   <div className="row p-3">
                                                                                   
                                                   <div className="col-md-12 mt-4">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <div className="cad card" id="cad1">
                                                                        <i className="fas fa-credit-card fa-lg" aria-hidden="true"></i>
                                                                        <p>No of Transactions</p>
                                                                        <span>150</span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-md-6">
                                                                    <div className="cad card">
                                                                        <i className="fas fa-mail-bulk fa-lg" aria-hidden="true"></i>
                                                                        <p>Messages</p>
                                                                        <span>15</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                   </div>                                                   
                                               </div>
                                               <div className="col-md-10 card ml-lg-3 mt-4">
                                                   <div className="row p-3">

                                                        <div class="col-md-12 mt-4">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                <p class="title2">NEW ORDERS</p> 
                                                                </div>
                                                                <div class="col-md-12">
                                                                    <div class="table-responsive">
                                                                            <table class="table white black-text  table-hover">
                                                                                <thead>
                                                                                </thead>
                                                                                <tbody>

                                                                                <Link to="/Transaction-Detials">
                                                                                    <tr class="">
                                                                                    <td>Agents Name</td>
                                                                                    <td>Precious vwarho</td>
                                                                                    <td>#2,000</td>
                                                                                    <td>2/6/2020</td>
                                                                                    <td>
                                                                                        <span class="Tactive">active</span></td>
                                                                                    <td>
                                                                                    </td>
                                                                                    </tr>
                                                                                  </Link>
                                                                                <Link to="/Transaction-Detials">
                                                                                    <tr class="">
                                                                                    <td>Agents Name</td>
                                                                                    <td>Precious vwarho</td>
                                                                                    <td>#2,000</td>
                                                                                    <td>2/6/2020</td>
                                                                                    <td>
                                                                                        <span class="success">success</span></td>
                                                                                    <td>
                                                                                    </td>
                                                                                    </tr>
                                                                                  </Link>

                                                                                <Link to="/Transaction-Detials">
                                                                                    <tr class="">
                                                                                    <td>Agents Name</td>
                                                                                    <td>Precious vwarho</td>
                                                                                    <td>#2,000</td>
                                                                                    <td>2/6/2020</td>
                                                                                    <td>
                                                                                        <span class="pending">pending</span></td>
                                                                                    <td>
                                                                                    </td>
                                                                                    </tr>
                                                                                  </Link>

                                                                                   
                                                                                </tbody>
                                                                            </table>
                                                                            </div>
                                                                 </div>
                                                            </div>
                                                        </div>
                                                </div>                                                   
                                               </div>
                                               
                                               <div className="col-md-1"></div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );
}

export default UserDetials;