import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import Sidebar from './Sidebar';
import configData from '../config.json';

const Users = () => {

  const [user, setUser] = useState([]);

  const agentFunc = async () => {
  
      return fetch(`${configData.SERVER_URL}/users`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson.data);
            setUser(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

  useEffect(()=>{
      agentFunc();
  }, [])

    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">USERS</p>
                                        </div>

                                        
                                              
                                <div class="col-md-12 mt-2 mb-2">    
                                            <div class="row">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ul class="breadcrumb">
                                                                   <li class="" style={{backgroundColor: "#ffd200", color: "#000"}}>
                                                                        <a href=""> All</a>
                                                                    </li>                                                                    
                                                                </ul>
                                                            </div>
                                                            <div class="col-md-5 search">
                                                                <input class="form-control" type="text" placeholder="Search" aria-label="Search"/>
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                        
                                        <div className="col-md-12 mt-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p className="title2">All Users</p>
                                            </div>
                                            <div className="col-md-6">
                                              <p class="title2">
                                            <a href="" data-toggle="modal" data-target="#exampleModal"  style={{backgroundColor:"#ffd200", color:"#000", fontSize:"12px", padding:"5px 10px", borderRadius:"10px"}}> Add Users</a>
                                              </p>
                                             
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12 mt-3">

                                                  <div className="table-responsive">
                                                    <table className="table white black-text  table-hover">
                                                      <thead>
                                                      </thead>
                                                      <tbody>
                                        {user.map(p => (
                                                <tr className="">
                                                  <td>{p.firstName} {p.lastName}</td>
                                                  <td>{p.phoneNumber}</td>
                                                  <td>{p.email}</td>
                                                  <td>
                                                    <Link to={`/User-Detials/${p._id}`}>
                                                      <i className="fas fa-pen" style={{color: "#000"}}></i>
                                                      </Link>
                                                  </td>
                                                  <td>
                                                    <a href="">
                                                      <i className="fas fa-trash" style={{color: "red"}}></i>
                                                    </a>
                                                    </td>
                                                </tr>

                                            ))} 
                                                       </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>






                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );

}

export default Users;