import React, {Component} from 'react';
import Sidebar from './Sidebar';
import $ from 'jquery';
import AddProduct from './components/AddProduct';
import Commission from '../admin/components/commision';

const Administration = () => {

    return (
    
		<div className="wrapper d-flex align-items-stretch">
           
    <Sidebar />

    <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
    <div className="row">
                 <div className="col-md-12">
                     <div className="row">
                         
                         <div className="col-md-12" id="contentDash">
                             <div className="row">

                                 <div className="col-md-12 mt-2">
                                     <p className="title">ADMINISTRATION</p>
                                 </div>

                                 <div className="col-md-12 mt-4">
                                   <div className="row">
                                     <div className="col-md-12">
                                       <div className="row">
                                          <div class="col-md-6 mt-3">
                                                <AddProduct/>
                                              </div>

                                            <div class="col-md-6 mt-3">
                                                 <Commission/>
                                            </div>

                                       </div>
                                     </div>

                                   </div>
                                 </div>




                             </div>
                         </div>
                   
                     </div>
                 </div>
             </div>
             
     </div>

   </div>


    );

}
export default Administration;