
import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import logo from '../assets/img/logo2.png';
import configData from '../config.json';
import {useParams} from "react-router-dom";
import { format } from 'date-fns' 

const TransactionDetials = () => {

    let { id } = useParams();
    const [trans, setTrans] = useState([]);
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [agentfirstName, setagentfirstName] = useState('Searching for Agent');
    const [agentlastName, setagentlastName] = useState(''); 
    const [agentphoneNumber, setagentPhoneNumber] = useState('');
    const [productName, setproductName] = useState(''); 
    const [loading, setloading] = useState(true);

    const transFunc = async () => {
    
        return fetch(`${configData.SERVER_URL}/orders/${id}`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson.data);
              setfirstName(responseJson.data.user.firstName)
              setlastName(responseJson.data.user.lastName)
              setPhoneNumber(responseJson.data.user.phoneNumber)
              
             if(responseJson.data.agent){
                setagentfirstName(responseJson.data.agent.firstName)
                setagentlastName(responseJson.data.agent.lastName)
                setagentPhoneNumber(responseJson.data.agent.contactPhoneNumber)
             }

              setproductName(responseJson.data.product.name)
              setTrans(responseJson.data);
              setloading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
  
  
    useEffect(() => {
        const interval=setInterval(()=>{
            transFunc();
        },3000)
        
        return()=>clearInterval(interval)
   },[]);



    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">

               {loading ? <>
                       <p>loading</p> 
                       </> 
                          :
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">TRANSACTIONS DETAILS</p>
                                        </div>

                                 
                                        <div class="col-md-12 mt-4">
                                            <div class="row">
                                                <div class="col-md-6">
                                                   <div class="card p-4" id="">
                                                       <div class="row">
                                                           <div class="col-3"></div>
                                                           <div class="col-6">                              
                                                               <span class="text-center font-weight-bold mt-2">Transaction ID:</span>
                                                               <span class="p"> {trans._id}</span>
                                                               <hr/>       
                                                               <span class="text-center font-weight-bold mt-2">Confirmation Code:</span>
                                                               <span class="p"> {trans.confirmationCode}</span>
                                                               
                                                           </div>
                                                           <div class="col-3"></div>
                                                           <div class="col-md-12 mt-5">
                                                               <span class="text-center font-weight-bold">Date:</span>
                                                               <span class="p" style={{float:"right"}}>{format(new Date(trans.deliveryTime), 'MMMM, do, yyy')}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Time:</span>
                                                               <span class="p" style={{float:"right"}}>{format(new Date(trans.deliveryTime), 'hh:mm a')}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Customers Name:</span>
                                                               <span class="p" style={{float:"right"}}>{firstName} {lastName}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Customers Phone Number:</span>
                                                               <span class="p" style={{float:"right"}}>{phoneNumber}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Agent's Name:</span>
                                                               <span class="p" style={{float:"right"}}>{agentfirstName} {agentlastName}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Agent's Phone Number:</span>
                                                               <span class="p" style={{float:"right"}}>{agentphoneNumber}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Product Type:</span>
                                                               <span class="p" style={{float:"right"}}>
                                                                   {productName}
                                                               </span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Quantity:</span>
                                                               <span class="p" style={{float:"right"}}>
                                                                   {trans.quantity}
                                                                </span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Price:</span>
                                                               <span class="p" style={{float:"right"}}>
                                                                   # {trans.product.costPerUnit}
                                                                </span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Service Charge:</span>
                                                               <span class="p" style={{float:"right"}}>#{trans.deliveryFee}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Payment Method:</span>
                                                               <span class="p" style={{float:"right"}}>{trans.paymentMethod}</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12 mt-4">
                                                             <span class="text-center font-weight-bold">GRAND TOTAL:</span>
                                                               <span class="p" style={{float:"right", fontSize:"30px"}}>
                                                                # {trans.totalAmount}
                                                              </span>  
                                                           </div>
                                                       </div>
                                                    </div>
                                                </div>
                                               
                                                <div class="col-md-4">
                                                   <div class="card p-4" id="">
                                                      <div className="row">
                                                           <div className="col-md-12">
                                                                <img className="img-fluid mx-auto d-block" src={logo} width="100"/>
                                                            </div>
                                                            
                                                            <div class="col-md-12 mt-2">
                                                                <span class=" font-weight-bold">Address:</span>
                                                                <span class="p" style={{float:"right"}}>{trans.address}</span>
                                                                <hr/>
                                                            </div>
                                                      </div>                                                       
                                                    </div>
                                                </div>
                                                <div class="col-md-2"></div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                                
                            </div>
                        </div>

                    }
                    </div>
                    
            </div>
   
          </div>


    );

}

export default TransactionDetials;