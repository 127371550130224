import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Dashboard from './admin/Dashboard';
import Administration from './admin/Administration';
import Agents from './admin/Agents';
import LogOilAgent from './admin/LogOilAgent';
import Users from './admin/Users';
import Admin from './admin/Admin';
import Transactions from './admin/Transactions';
// import {Messages} from './admin/Messages';
import {MessageDetails} from './admin/MessageDetails';
import TransactionDetials from './admin/TransactionDetials';
import AgentDetials from './admin/AgentDetails';
import UserDetials from './admin/UserDetails';
import {Profile} from './admin/Profile';
import {PushNotification} from './admin/PushNotification';
import ProductDetails from "./admin/productDetails";
import Logistics from "./admin/Logistics"
import OilGas from "./admin/OilGas"
import Login from "./admin/login";

function App() {
  return (
    <Router>
       <Switch>
          <Route path="/Dashboard" exact>
            <Dashboard />
          </Route>
          <Route path="/" exact>
            <Login />
          </Route>
          <Route path="/Profile">
            <Profile />
          </Route>
          <Route path="/Administration">
            <Administration />
          </Route>
          <Route path="/Agents" exact>
             <Agents/>
          </Route>
          <Route path="/LogOilAgent" exact>
             <LogOilAgent/>
          </Route> 
          <Route path="/Logistics" exact>
             <Logistics/>
          </Route> 
          <Route path="/OilGas" exact>
             <OilGas/>
          </Route> 
          <Route path="/Users">
             <Users/>
          </Route>
          <Route path="/Admin" exact>
             <Admin/>
          </Route>
          <Route path="/User-Detials/:id">
             <UserDetials/>
          </Route>
          <Route path="/Transactions">
             <Transactions/>
          </Route>
          {/* <Route path="/Messages">
             <Messages/>
          </Route> */}
          <Route path="/Message-Details">
             <MessageDetails/>
          </Route>
          <Route path="/Transaction-Detials/:id">
             <TransactionDetials/>
          </Route>
          <Route path="/productDetails/:id">
             <ProductDetails/>
          </Route>
          <Route path="/Agent-Detials">
             <AgentDetials/>
          </Route>
          <Route path="/Push-Notification">
             <PushNotification/>
          </Route>
        </Switch>
    </Router>
    
  );
}


export default App;
