
import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import {Link} from "react-router-dom";
import { format } from 'date-fns' 
import configData from '../config.json';

const AgentDetials = () => {

  const [overview, setoverview] = useState(true);
  const [profile, setprofile] = useState(false);
  const [agent, setAgent] = useState([]);
  const [vehicles, setVehicles] = useState();
  const [disableBtn, setDisabledBtn] = useState(false);

  const agentDet =  () => {

    const agentId = localStorage.getItem('agentId');
  
      return fetch(`${configData.SERVER_URL}/agents/${agentId}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson.data);
            setAgent(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

  const vechiletype =  (id) => {


    return fetch(`${configData.SERVER_URL}/auth/agent/update-vehicle-type`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "content-type": "application/json",
        "x-api-token":  window.localStorage.getItem("token")
      },
      body: JSON.stringify({
        id: id,
        vehicleType: "car"
      })
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
          console.log(responseJson);
          if(responseJson.status === "success"){
              setDisabledBtn(false)
              console.log(responseJson)
              alert(responseJson.message);
          }
          if(responseJson.status === "error"){
              setDisabledBtn(false)
              alert(responseJson.message);
          }

      })
      .catch((error) => {
        setDisabledBtn(false)
        console.error(error);
      })

    }

  useEffect(()=>{
    agentDet();
  }, [])

 
  const overviewFunction = ()=>{
    setoverview(true);
    setprofile(false);
  }
 
  const profileFunction = ()=>{
    setprofile(true);
    setoverview(false);
  }

  const decline =(id)=>{
    setDisabledBtn(true);

    return fetch(`${configData.SERVER_URL}/auth/agent/reject`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
        body: JSON.stringify({
          id: id
        })
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          console.log(responseJson)
          if(responseJson.status === "success"){
            setDisabledBtn(false)
            console.log(responseJson)
            alert(responseJson.message);
          }
          if(responseJson.status === "error"){
            setDisabledBtn(false)
            alert(responseJson.message);
              
          }

        })
        .catch((error) => {
          setDisabledBtn(false)
          console.error(error);
        })


  }


  const approve =(id)=>{
    setDisabledBtn(true);

    return fetch(`${configData.SERVER_URL}/auth/agent/approve`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
        body: JSON.stringify({
          id: id
        })
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          console.log(responseJson)
          if(responseJson.status === "success"){
            setDisabledBtn(false)
            console.log(responseJson)
            alert(responseJson.message);
          }
          if(responseJson.status === "error"){
            setDisabledBtn(false)
            alert(responseJson.message);
              
          }

        })
        .catch((error) => {
          setDisabledBtn(false)
          console.error(error);
        })


  }



    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="">AGENT PROFILE</p>
                                        </div>

              
                    
                    <div class="col-md-12 mt-4">
                        <div class="row">
                            <div class="col-md-4 card" id="profCard">
                                <div class="p-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                          <img className="img-fluid mx-auto d-block" src={`${configData.SERVER_URL}/images/avatars/${agent.avatar}`} width="100"/>
                                        </div>
                                        <div className="col-md-12">
                                          <h6 className='text-center mt-3 profile'>{agent.firstName} {agent.lastName}</h6>
                                            <p className='text-center'>
                                            {agent.phoneNumber}</p>
                                            <p className='text-center' style={{color:'green'}}>
                                            {agent.approvalStatus}</p>
                                            <p className='text-center' style={{color:'green'}}>
                                            {agent.availabilityStatus}</p>
                                        </div>
                                        <div className="col-md-12 mt-4" id="ProfileSidebar"> 
                                            <ul className="list-unstyled components mb-5">
                                            <li class="active" onClick={()=> overviewFunction()}>
                                            <Link to="#">Overview</Link>
                                            <hr/>
                                            </li>
                                            <li>
                                            <Link to="#">Transactions</Link>
                                            <hr/>
                                            </li>
                                            <li onClick={()=>profileFunction()}>
                                            <Link to="#">Profile</Link>
                                            </li>
                                            <span class="font-weight-bold">         
                                              <p className=" mt-4" style={{backgroundColor:"#ffd200", color:"#fff", fontSize:"12px", padding:"5px 10px", borderRadius:"10px", cursor:'pointer', textAlign:'center'}} onClick={()=> vechiletype(agent._id)}>
                                              {disableBtn ?<> Please wait </>:<>Make Car Agent </> } 
                                              </p>
                                              </span>
                                        </ul>
                                        </div> 
                                    </div>
                                  </div>
                            </div>
                            <div class="col-md-6 ml-lg-4 card" id="profCard">
                                <div class="p-4">
                                    <div class="row">

                                {overview && (<>


                                                      
                    <div className="col-md-12 mt-4">
                        <div className="row">
                        <div className="col-md-4">
                        <div className="cad card" id="cad1">
                            <i className="fas fa-credit-card fa-lg" aria-hidden="true"></i>
                            <p>Transactions</p>
                            {/* <span>1500</span> */}
                            </div>

                        </div>
                        <div className="col-md-4">
                          <div className="cad card">
                              <i className="fa fa-users fa-lg" aria-hidden="true"></i>
                              <p>Completed</p>
                              {/* <span>150</span> */}
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="cad card">
                            <i className="fa fa-users fa-lg" aria-hidden="true"></i>
                            <p>Cash Out</p>
                            {/* <span>#15000</span> */}
                            </div>
                        </div>
                    </div>
                      </div>
            
            <div class="col-md-12 mt-4">
                <div class="row">
                    <div class="col-md-12">
                      <p class="title2">CURRENT TRANSACTIONS</p> 
                    </div>
                    <div class="col-md-12">
                                <div class="table-responsive">
                                  <table class="table white black-text  table-hover">
                                    <thead>
                                      </thead>
                                      {/* <tbody>

                                        <tr class="">
                                          <td>Agents Name</td>
                                          <td>Precious vwarho</td>
                                          <td>#2,000</td>
                                          <td>2/6/2020</td>
                                          <td>
                                            <span class="Tactive">active</span></td>
                                          <td>
                                      <Link to="/Transaction-Detials">
                                          <i class="fas fa-edit mr-2" aria-hidden="true"></i>
                                        </Link>
                                          </td>
                                        </tr>

                                      </tbody> */}
                                  </table>
                                </div>
                            
                    </div>
                </div>
            </div>

                                    </>)}




      {profile && (<>


          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Date of Application:</span>
                <span class="p" style={{float:"right"}}>{format(new Date(agent.createdAt), 'MMMM, do, yyy')}</span>
                <hr/>
            </div>

          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">First Name:</span>
                <span class="p" style={{float:"right"}}>{agent.firstName}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Last Name:</span>
                <span class="p" style={{float:"right"}}>{agent.lastName}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Email:</span>
                <span class="p" style={{float:"right"}}>{agent.email}</span>
                <hr/>
            </div>

            <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Phone Number:</span>
                <span class="p" style={{float:"right"}}>{agent.phoneNumber}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">sex:</span>
                <span class="p" style={{float:"right"}}>{agent.sex}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Date Of Birth:</span>
                <span class="p" style={{float:"right"}}>{agent.dateOfBirth}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Nationality:</span>
                <span class="p" style={{float:"right"}}>{agent.nationality}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">State Of Origin:</span>
                <span class="p" style={{float:"right"}}>{agent.origin}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Local Government Area:</span>
                <span class="p" style={{float:"right"}}>{agent.local}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Place Of Birth:</span>
                <span class="p" style={{float:"right"}}>{agent.placeOfBirth}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Residential Address:</span>
                <span class="p" style={{float:"right"}}>{agent.residentialAddress}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Identification Number:</span>
                <span class="p" style={{float:"right"}}>{agent.identificationNumber}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">About Agent:</span>
                <span class="col-12 p" style={{float:"right"}}>{agent.aboutYou}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Area:</span>
                <span class="p" style={{float:"right"}}>{agent.area}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Area Description:</span>
                <span class="p" style={{float:"right"}}>{agent.areaDesc}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Utility Bill:</span>
                <span class="p" style={{float:"right"}}>
                <img src={`${configData.SERVER_URL}/images/avatars/${agent.utilityBill}`} className="img-fluid mx-auto d-block prodImg"/>
                  </span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Recommendation:</span>
                <span class="p" style={{float:"right"}}>
                <img src={`${configData.SERVER_URL}/images/avatars/${agent.recommendation}`} className="img-fluid mx-auto d-block prodImg"/>
                  </span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Occupation:</span>
                <span class="p" style={{float:"right"}}>{agent.occupation}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Bank Name:</span>
                <span class="p" style={{float:"right"}}>{agent.bankName}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Bank Account Name:</span>
                <span class="p" style={{float:"right"}}>{agent.bankAccountName}</span>
                <hr/>
            </div>
          <div class="col-md-12 mt-2">
                <span class="text-center font-weight-bold">Bank Account Number:</span>
                <span class="p" style={{float:"right"}}>{agent.bankAccountNumber}</span>
                <hr/>
            </div>

          <div class="col-md-12 mt-2">
              {(agent.approvalStatus === "rejected" || agent.approvalStatus === "pending") &&
                <span class="font-weight-bold">         
                  <p style={{backgroundColor:"#ffd200", color:"#fff", fontSize:"12px", padding:"5px 10px", borderRadius:"10px", cursor:'pointer', textAlign:'center'}} onClick={()=> approve(agent._id)}>
                  {disableBtn ?<> Please wait </>:<> Approve Agent </> } 
                  </p>
                  </span>}

              {(agent.approvalStatus === "approved" || agent.approvalStatus === "pending") &&
                <span class="p">
                  <p style={{backgroundColor:"red", color:"#fff", fontSize:"12px", padding:"5px 10px", borderRadius:"10px", cursor:'pointer', textAlign:'center'}} onClick={()=> decline(agent._id)}>{disableBtn ?<> Please wait </>:<>Decline Agent </> } 
                  </p>
                </span>}
            </div>
            


            </>)}








                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                    </div>





                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );

}
export default AgentDetials;