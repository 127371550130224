
import React, {useEffect, useState} from 'react';

const initialState = {
  type: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber:''
};

export const Context = React.createContext();

const Store = ({children}) => {
  const [state, setState] = useState(initialState);

  return (
    <Context.Provider value={[state, setState]}>
      {children}
    </Context.Provider>
  );
};


export default Store;
