import React, {useState, useEffect} from 'react';
import {Redirect, Link} from "react-router-dom";
import { useForm } from "react-hook-form";
import configData from '../../config.json';

const Commission = () => {

  const [charge, setCharge] = useState([]);
    const { register, handleSubmit, errors } = useForm();
    const [btnisloading, setBtnIsLoading] = useState(false);
    const [agentFee, setagentFee] = useState([]);
    const [serviceCharge, setserviceCharge] = useState();
    const [serviceChargeAbove30Litres, setserviceChargeAbove30Litres] = useState();
    const [loading, setLoading] = useState(true);
    const [agentCommission, setagentCommission] = useState(0);


  
      const getCommission = async () => {
        setBtnIsLoading(true);
    
        return fetch(`${configData.SERVER_URL}/settings/agent-commission`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
              setBtnIsLoading(false)
              console.log(responseJson);
              setagentCommission(responseJson.data.agentCommission);
          })
          .catch((error) => {
            setBtnIsLoading(false)
            console.error(error);
          });
      }


      
      const comModal = ()=>{
        setagentCommission(agentCommission);
        window.$("#comModal").modal("show"); 
      }
  


      
  const serviceM = ()=>{
      setserviceCharge(serviceCharge);
      setserviceChargeAbove30Litres(serviceChargeAbove30Litres);
      window.$("#serviceModal").modal("show"); 
    }



    const getCharge = async () => {
      setBtnIsLoading(true);
  
      return fetch(`${configData.SERVER_URL}/settings/service-charge`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            setBtnIsLoading(false)
            console.log(responseJson);
            // setCharge(responseJson.data);
            setserviceCharge(responseJson.data.serviceCharge);
            setserviceChargeAbove30Litres(responseJson.data.serviceChargeAbove30Litres);
        })
        .catch((error) => {
          setBtnIsLoading(false)
          console.error(error);
        });
    }


    const updateService = () => {
        setBtnIsLoading(true)

        return fetch(`${configData.SERVER_URL}/settings/service-charge`, {
            method: "post",
            headers: {
              Accept: "application/json",
              "content-type": "application/json",
              "x-api-token":  window.localStorage.getItem("token")
            },
            body: JSON.stringify({
              serviceCharge: Number(serviceCharge),
              serviceChargeAbove30Litres: Number(serviceChargeAbove30Litres)
            })
          })
            .then((response) => response.json())
            .then(async (responseJson) => {
              console.log(responseJson)
              if(responseJson.status === "success"){
                setBtnIsLoading(false)
                alert(responseJson.message)
              }
              if(responseJson.status === "error"){
                alert(responseJson.message)
                setBtnIsLoading(false)                 
              }
    
            })
            .catch((error) => {
              setBtnIsLoading(false)
              console.error(error);
            })
            
        }

    const updateComm = () => {
      setBtnIsLoading(true)

      return fetch(`${configData.SERVER_URL}/settings/agent-commission`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "content-type": "application/json",
            "x-api-token":  window.localStorage.getItem("token")
          },
          body: JSON.stringify({
            agentCommission: Number(agentCommission)
          })
        })
          .then((response) => response.json())
          .then(async (responseJson) => {
            console.log(responseJson)
            if(responseJson.status === "success"){
              setBtnIsLoading(false)
              alert(responseJson.message)
            }
            if(responseJson.status === "error"){
              alert(responseJson.message)
              setBtnIsLoading(false)                 
            }
  
          })
          .catch((error) => {
            setBtnIsLoading(false)
            console.error(error);
          })

    }
    
    useEffect(()=>{
      getCharge();
      getCommission();
    }, [])





    return (
        
        <div class="row">
        <div class="col-6">
          <p class="title2">Energie Service Charge</p> 
        </div>
        <div class="col-6">
            <p class="title2">
                <a  onClick={() => serviceM()} data-toggle="modal" data-target="#serviceModal"
                style={{backgroundColor:"#ffd200", color:"#000", fontSize:"12px", padding:"5px 10px", borderRadius:"10px"}}>Service Charge</a>
            </p>
            <div class="modal fade" id="serviceModal" tabindex="-1" role="dialog" aria-labelledby="serviceModalLabel" aria-hidden="true" style={{display: "none"}}>
            <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Add Energie Commission</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                       <span aria-hidden="true">×</span>
                    </button>
               </div>
              <div class="modal-body">
                  <div class="row">
                    <div class="col-1"></div>
                      <div class="col-10">
                        <form class="">
                          <div class="row">
                              <div class="col-12">
                                  <input type="text" class="p-1" value={serviceCharge}  onChange={(e) => setserviceCharge(e.target.value)}  name="serviceCharge" ref={register({ required: true})}/>
                                  {errors.serviceCharge && <p>Please add Service Amount</p>}
                              </div>
                              <div class="col-12 mt-2">
                                  <input type="text" class="p-1" value={serviceChargeAbove30Litres}  onChange={(e) => setserviceChargeAbove30Litres(e.target.value)}  name="serviceChargeAbove30Litres" ref={register({ required: true})}/>
                                  {errors.serviceChargeAbove30Litres && <p>Please add Service Amount</p>}
                              </div>
                              <div class="col-12">
                                  <button onClick={()=> updateService()} class="btn btn-warn my-4 btn-block waves-effect waves-light btn-sm" type="submit" name="add" disabled={btnisloading}>
                                    {btnisloading ? (<> Please Wait.. </>) : ( <> Update Service Charge </>)}
                                  </button>
                              </div>
                          </div>
                        </form>
                      </div>
                    <div class="col-1"></div>
                  </div>
              </div>
            </div>
            </div>
            </div>
            
        </div>
        {/* charge */}
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-10">
                    
                    <div class="table-responsive">
                      <table class="table white black-text  table-hover">
                        <thead>
                          </thead>
                          <tbody>
                        {/* {charge.map(c => (<> */}
                            <tr class="">
                              <td>First Service Charge</td>
                              <td># {serviceCharge}</td>
                            </tr>
                            <tr class="">
                              <td>Second Service Charge</td>
                              <td># {serviceChargeAbove30Litres}</td>
                            </tr>
                        {/* </>))}  */}
                          </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>



    {/* <div className=""> */}
        <div class="col-6 mt-4">
          <p class="title2">Agent Commission</p> 
        </div>
        <div class="col-6 mt-4">
            <p class="title2">
                <a  onClick={() => comModal()} data-toggle="modal" data-target="#comModal"
                style={{backgroundColor:"#ffd200", color:"#000", fontSize:"12px", padding:"5px 10px", borderRadius:"10px"}}>Service Charge</a>
            </p>
            <div class="modal fade" id="comModal" tabindex="-1" role="dialog" aria-labelledby="comModalLabel" aria-hidden="true" style={{display: "none"}}>
            <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Agent Commission</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                       <span aria-hidden="true">×</span>
                    </button>
               </div>
              <div class="modal-body">
                  <div class="row">
                    <div class="col-1"></div>
                      <div class="col-10">
                        <form class="">
                          <div class="row">
                              <div class="col-12 mt-2">
                                  <input type="text" class="p-1" value={agentCommission}  onChange={(e) => setagentCommission(e.target.value)}  name="agentCommission" ref={register({ required: true})}/>
                                  {errors.agentCommission && <p>Please add Service Amount</p>}
                              </div>
                              <div class="col-12">
                                  <button onClick={()=> updateComm()} class="btn btn-warn my-4 btn-block waves-effect waves-light btn-sm" type="submit" name="add" disabled={btnisloading}>
                                    {btnisloading ? (<> Please Wait.. </>) : ( <> Update Service Charge </>)}
                                  </button>
                              </div>
                          </div>
                        </form>
                      </div>
                    <div class="col-1"></div>
                  </div>
              </div>
            </div>
            </div>
            </div>
            
        </div>
        {/* </div> */}

        <div class="col-md-12">
            <div class="row">
                <div class="col-md-10">
                    
                    <div class="table-responsive">
                      <table class="table white black-text  table-hover">
                        <thead>
                          </thead>
                          <tbody>
                            <tr class="">
                              <td>Agent Commission</td>
                              <td># {agentCommission}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                </div>
            </div>
        </div>





    </div>
    );
  }

  export default Commission;