import React, {useEffect} from 'react';
import $ from 'jquery';
import Popper from 'popper.js';
import '../assets/css/bootstrap.min.css';
import '../assets/css/mdb.css';
import '../assets/css/font-awesome.css';
import '../assets/css/fontawesome/css/all.min.css';
import '../assets/icofont/css/icofont.css';
import '../assets/css/sidestyle.css';
import '../assets/css/Adminsidebar.css'
import {Link} from "react-router-dom";

import logo from '../assets/img/logo2.png';

const Sidebar = () => {

  
  useEffect(()=>{
    var fullHeight = function () {

      $('.js-fullheight').css('height', $(window).height());
      $(window).resize(function () {
        $('.js-fullheight').css('height', $(window).height());
      });

    };
    fullHeight();

    $('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
    });
  } , [])


  const logout = () => {
    window.localStorage.clear();
    window.location = window.location.origin + "/";
}

useEffect(() => {
    const token = window.localStorage.getItem('token');
    if(!token){
        window.location = window.location.origin + "/";
    }
})


    
    return (

        <nav id="sidebar">
            <div class="custom-menu">
                <button type="button" id="sidebarCollapse" class="btn btn-primary">
                <i class="fa fa-bars"></i>
                <span class="sr-only">Toggle Menu</span>
                </button>
            </div>
            <div class="p-4">
              <h1><a href="index.html" class="logo">
                  <img className="img-fluid mx-auto d-block" src={logo} width="100"/>
              </a></h1>
        <ul class="list-unstyled components mb-5">
          <li class="active">
            <Link to="/Dashboard"><span class="fas fa-home mr-3"></span> Dashboard</Link>
          </li>
          {JSON.parse(window.localStorage.getItem("Admin") === "admin") && (
                <>
          <li>
              <Link to="/Administration"><span class="fas fa-users-cog mr-3"></span> Administration</Link>
          </li>
          <li>
              <Link to="/LogOilAgent"><span class="fas fa-users-cog mr-3"></span> Logistic Agents</Link>
          </li>
          <li>
          <Link to="/OilGas"><span class="fas fa-user-friends mr-3"></span> Oil and Gas Deals</Link>
          </li>
          <li>
          <Link to="/Logistics"><span class="fas fa-user-friends mr-3"></span> Logistics</Link>
          </li>
          <li>
          <Link to="/Admin"><span class="fas fa-user-friends mr-3"></span> Admin</Link>
          </li>
          <li>
          <Link to="/Agents"><span class="fas fa-user-friends mr-3"></span> Agents</Link>
          </li>
          <li>
          <Link to="/Users"><span class="fas fa-users mr-3"></span> Users</Link>
          </li>
                    </>)}
          <li>
          <Link to="/Transactions"><span class="fas fa-credit-card mr-3"></span> Transactions</Link>
          </li>
          {/* <li>
          <Link to="/Profile"><span class="fas fa-user-tie mr-3"></span> My Account</Link>
          </li> */}
          <li>
              <a onClick={()=> logout()} style={{cursor:'pointer'}}><span class="fas fa-sign-out-alt mr-3"></span> Logout</a>
          </li>
        </ul>


        <div class="footer">
                     <p>  
                      Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved
                      </p>
        </div>

      </div>
    </nav>




    )
  
}

export default Sidebar;