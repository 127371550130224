import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import  title from '../assets/img/title.png';
import {Link} from "react-router-dom";
import configData from '../config.json';
import { format } from 'date-fns' 

const Dashboard = () => {

    const [trans, setTrans] = useState([]);
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [loading, setloading] = useState(true);
    const [date, setDate] = useState([]);
  
    const transFunc = async () => {
    
        return fetch(`${configData.SERVER_URL}/orders`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson.data);
              setTrans(responseJson.data);
              setDate(responseJson.data.deliveryTime);
              setloading(false);
          })
          .catch((error) => {
            console.error(error);
          });
      }
  
    useEffect(()=>{
       transFunc();
    }, [])
      
    
    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="HomeContent" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-8" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">DASHBOARD</p>
                                        </div>

                                        <div className="col-md-12">
                                           <img src={title} className="img-fluid mt-2"/>
                                        </div>

                                        <div className="col-md-12 mt-4">
                                            <div className="row">
                                                <div className="col-md-4">
                                                   <div className="cad card" id="cad1">
                                                       <i className="fas fa-credit-card fa-lg" aria-hidden="true"></i>
                                                       <p>Transactions</p>
                                                       {/* <span>15000</span> */}
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                   <div className="cad card">
                                                       <i className="fa fa-users fa-lg" aria-hidden="true"></i>
                                                       <p>Users</p>
                                                       {/* <span>15000</span> */}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                   <div className="cad card">
                                                       <i className="fas fa-user-friends fa-lg" aria-hidden="true"></i>
                                                       <p>Agents</p>
                                                       {/* <span>15000</span> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-4">
                                            <div className="row">
                                                    <div class="col-md-12">

                                                    </div>
                                                
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                                <div className="col-md-4" id="transaction">
                                    <div className="row">
                                        <div className="" id="topNav">
                                            <div className="row">
                                                <div className="col-8" id="topNav1">
                                                    <div className="row">
                                                        <div className="col-12 mt-3">
                                                          <p className="text-white">Total: 
                                                              <span className="ml-2">#50,000,000</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4" id="topNav2">
                                                   <img src="../img/user.png" className="img-fluid mx-auto d-block" id="image2"/>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12" id="navBottom">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <p className="font-weight-bold">RECENT  TRANSACTION</p>
                                                </div>
                                                <div className="col-md-12">
                                    {/* {group.map(b => {
                                        if(b._id === groupId) 
                                            return
                                        <option value={b._id}>{b.name} ty </option>
                                      })} */}

                                                {/* {trans.map(t => (<>
                                                    <p className="mt-2" style={{backgroundColor: "#f7f7f7", padding:"5px 5px;"}}>{format(new Date(t.deliveryTime), 'MMMM, do, yyy')}</p>
                                                    <ul className="list-group list-group-horizontal">
                                                      <li className="list-group-item">{t.user.firstName}</li>
                                                      <li className="list-group-item">{t.quantity} {t.product.unit}</li>
                                                      <li className="list-group-item">{t.product.name}</li>
                                                      <li className="list-group-item" style={{color:"#000"}}># 50,000</li>
                                                    </ul>

                                                  </> ))}      */}
                                                    
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );
  

}
export default Dashboard;
  