import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import {Link} from "react-router-dom";
import configData from '../config.json';
import { format } from 'date-fns' 

const Transactions = ()=> {

  const [trans, setTrans] = useState([]);
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [loading, setloading] = useState(true);

  const transFunc = async () => {
  
      return fetch(`${configData.SERVER_URL}/orders`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson.data);
            // setfirstName(responseJson.data.user.firstName);
            // setlastName(responseJson.data.user.lastName);
            setTrans(responseJson.data);
            setloading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }

    useEffect(() => {
      const interval=setInterval(()=>{
          transFunc();
      },3000)
      
      return()=>clearInterval(interval)
      },[]);

    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">TRANSACTIONS</p>
                                        </div>

                                                       
                                <div class="col-md-12 mt-2 mb-2">    
                                            <div class="row">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ul class="breadcrumb">
                                                                   <li class="" style={{backgroundColor: "#ffd200", color: "#000"}}>
                                                                        <a href="">Active </a>
                                                                    </li>
                                                                      <li class="li"> 
                                                                         <a href="">Successful</a>
                                                                        </li>
                                                                      <li class="li"> 
                                                                         <a href="">Pending</a>
                                                                        </li>
                                                                        
                                                                </ul>
                                                            </div>
                                                            <div class="col-md-5 search">
                                                                <input class="form-control" type="text" placeholder="Search" aria-label="Search"/>
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                <div class="col-md-12 mt-4">
                                    <div class="row">
                                        <div class="col-md-12">
                                          <p class="title2">All TRANSACTIONS</p> 
                                        </div>
                                        <div class="col-md-12">

               {loading ? <>
                       <p>loading</p> 
                       </> 
                          :
                                            <div class="row">
                                                <div class="col-md-11 mt-3">

                                                    <div class="table-responsive">
                                                      <table class="table white black-text  table-hover">
                                                        <thead>
                                                          </thead>
                                                          <tbody>

                                                    {trans.map(u => (
                                                        <tr class="">
                                                          <td>{u.user.firstName} {u.user.lastName}</td>
                                                          <td>{u.quantity} {u.product.unit}</td>
                                                          <td>{u.product.name}</td>
                                                          <td># {u.totalAmount}</td>
                                                          <td>{format(new Date(u.deliveryTime), 'MMMM, do, yyy')}</td>
                                                          <td>
                                                              {(u.status === "delivered") && <span style={{backgroundColor:'green', color:'#fff', padding:5, borderRadius:20, fontSize:12}}>Delivered</span>}
                                                              {(u.status === "in transit") && <span style={{backgroundColor:'orange', color:'#fff', padding:5, borderRadius:20, fontSize:12}}>in transit</span>}
                                                              {(u.status === "searching for agent") && <span style={{backgroundColor:'#ffd200', color:'#000', padding:5, borderRadius:20, fontSize:12}}>Searching for agent</span>}
                                                            </td>
                                                          <td>
                                                      <Link to={`/Transaction-Detials/${u._id}`}>
                                                          <i class="fas fa-edit mr-2" aria-hidden="true"></i>
                                                        </Link>
                                                          </td>
                                                        </tr>

                                                       ))} 

                                                          </tbody>
                                                      </table>
                                                    </div>
                                                </div>
                                            </div>
                         }
                                        </div>
                                    </div>
                                </div>




                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );
  

}

export default Transactions;
  