import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Sidebar from './Sidebar';
import logo from '../assets/img/logo2.png';

export class MessageDetails extends Component {

    
  render() {
    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">MESSAGES</p>
                                        </div>

                                        
                                        <div class="col-md-12 mt-4">
                                            <div class="row">
                                                <div class="col-md-12">
                                                <p class="title2">​​Precious Vwarho</p> 
                                                </div>
                                                <div class="col-md-10">
                                                   <div className="row">
                                                        <div className="col-md-6">
                                                            <h6>Paystack hello@paystack.com> Unsubscribe</h6>
                                                         </div>
                                                        <div className="col-md-6">
                                                            <h6 className="float-right">Sep 24, 2020, 11:00 AM (1 day ago)</h6>
                                                         </div>
                                                         <div className="col-md-12 mt-3">
                                                             <p>Hi ​​Precious,​
                                                                Paystack is undergoing scheduled maintenance for 1 hour on Sunday 4th October 2020, between 3 am - 4 am (WAT) / 2 am - 3 am (GMT).

                                                                This is a routine exercise to ensure that we continue to deliver a great experience to you.

                                                                Customers will be unable to pay you during this period.
                                                                Once the maintenance is complete, everything will remain the same on your end. Nothing changes.
                                                                Here are a few recommendations on how to prepare:

                                                                Kindly share this update with all members of your team so they're aware of the exercise
                                                                Consider pre-emptively informing your customers about the service disruption
                                                                For updates during the maintenance period, kindly check out the Paystack Status page.

                                                                Warm regards,
                                                                Your friends at Paystack</p>
                                                         </div>
                                                         <div className="col-md-12 mt-3">
                                                             <p><hr/></p>
                                                         </div>
                                                   </div>
                                                </div>
                                                <div class="col-md-2">
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );
  }

}