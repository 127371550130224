import React, {Component} from 'react';
import Sidebar from './Sidebar';

export class PushNotification extends Component {

    
  render() {
    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">PUSH NOTIFICATION</p>
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );
  }

}