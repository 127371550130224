import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import Sidebar from './Sidebar';
import configData from '../config.json';
import { useForm } from "react-hook-form";

const LogOilAgent = () => {
    const { register, handleSubmit, errors } = useForm();

  const [user, setUser] = useState([]);
  const [btnisloading, setBtnIsLoading] = useState(false);

  const getAgent = async () => {
  
      return fetch(`${configData.TEST_URL}/energieAgent`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson);
            setUser(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
 
    const [catType, setCatType] = useState('logistics')
    const [city, setCity] = useState('');
    const [cities, setCities] = useState([]);

    
      const getState = async() => {
           
        return fetch(`${configData.TEST_URL}/state`, {
            method: "get",
            headers: {
              Accept: "application/json",
              // "x-auth-token": JSON.parse(await AsyncStorage.getItem('token'))
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              console.log(responseJson)
              setCities(responseJson.state);
            })
            .catch((error) => {
              console.error(error);
            });
      }
      
      const handleSelectChange = (event) => {
        setCatType(event.target.value);
      };

  useEffect(()=>{
      getState();
      getAgent();
  }, [])


  const [image, setImage] = useState(null); 

  const handleImageChange = (event) => {
        setImage(event.target.files[0]); 
    };


  const addAdmin = async(data) => {
    
    setBtnIsLoading(true);

    const formData = new FormData();
    formData.append('profileImg',  image); 
    // Append other fields to the FormData object
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phone', data.phone);
    formData.append('address', data.address);
    formData.append('stateId', city);
    formData.append('catType', catType);

    return fetch(`${configData.TEST_URL}/energieAgent`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "x-api-token": window.localStorage.getItem("token")
        },
        body: formData // Use FormData object as the body of the request
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
          if(responseJson.status === "success"){
            alert("User added successfully");
            setBtnIsLoading(false);
          }
          if(responseJson.status === "error"){
            alert(responseJson.message);
            console.log(responseJson.message);
            setBtnIsLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
  };



    return (<>

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">USERS</p>
                                        </div>

                                        
                                              
                              

                                        
                                        <div className="col-md-12 mt-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p className="title2">All Admin</p>
                                            </div>
                                            <div className="col-md-6">
                                              <p class="title2">
                                            <a href="" data-toggle="modal" data-target="#exampleModal"  style={{backgroundColor:"#ffd200", color:"#000", fontSize:"12px", padding:"5px 10px", borderRadius:"10px"}}> Add Admin</a>
                                              </p>
                                             
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12 mt-3">

                                                  <div className="table-responsive">
                                                    <table className="table white black-text  table-hover">
                                                      <thead>
                                                      </thead>
                                                      <tbody>
                                        {user.map(p => (
                                                <tr className="">
                                                  <td>{p.name}</td>
                                                  <td>{p.phone}</td>
                                                  <td>{p.email}</td>
                                                  <td>{p.address}</td>
                                                  <td>{p.catType}</td>
                                                </tr>

                                            ))} 
                                                       </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>






                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
            </div>
   
          </div>



     
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Agent</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                <div class="col-1"></div>
                                    <div class="col-10">
                                      <form onSubmit={handleSubmit(addAdmin)} class="">
                                        <div class="row">
                                          {/* <input type="file" onChange={handleImageChange} /> */}

                                          <input type="file" name="image" 
                                            id="imageInput"
                                            accept="image/*"
                                            onChange={handleImageChange}/>

                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="First Name" name="name" ref={register({ required: true, })}/>
                                                {errors.name && <p>Agent Name  is required </p>}
                                            </div>

                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="Phone Number" name="phone" ref={register({ required: true, })}/>
                                                {errors.phone && <p>Phone  is required </p>}
                                            </div>

                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="Email" name="email" ref={register({ required: true, })}/>
                                                {errors.email && <p>Email  is required </p>}
                                            </div>
                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="Address" name="address" ref={register({ required: true, })}/>
                                                {errors.address && <p>Address  is required </p>}
                                            </div>
                                <div className="col-12">
                                    <select value={city} onChange={(e) => setCity(e.target.value)} className="form-control mt-4 p-2">
                                        <option value="">Select State</option>
                                        {cities.map((city) => (
                                            <option key={city._id} value={city._id}>{city.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-12">
                                <label htmlFor="exampleFormControlSelect1">Select Category</label>
                                <select
                                    className="form-control"
                                    id="exampleFormControlSelect1"
                                    onChange={handleSelectChange}
                                    value={catType} 
                                >
                                    <option value="logistics">Logistics</option>
                                    <option value="oilDeal">Oil Deal</option>
                                </select>
                                </div>
                                            <div class="col-12">
                                                <button class="btn btn-warn my-4 btn-block waves-effect waves-light btn-sm" type="submit" name="add" disabled={btnisloading}>
                                              {btnisloading ? (<> Please Wait.. </>) : ( <> Add Agent</>)}</button>
                                            </div>
                                        </div>
                                      </form>
                                    </div>
                                <div class="col-1"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

    </>);

}

export default LogOilAgent;