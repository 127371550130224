import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import Sidebar from './Sidebar';
import configData from '../config.json';
import { useForm } from "react-hook-form";

const Admin = () => {
    const { register, handleSubmit, errors } = useForm();

  const [user, setUser] = useState([]);
  const [supportUser, setsupportUser] = useState([]);
  const [btnisloading, setBtnIsLoading] = useState(false);

  const agentFunc = async () => {
  
      return fetch(`${configData.SERVER_URL}/admins/support-staff`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson);
            setUser(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }


  useEffect(()=>{
      agentFunc();
    //   supportFunc();
  }, [])



  const addAdmin = (data) => {

    setBtnIsLoading(true);

    return fetch(`${configData.SERVER_URL}/admins/support-staff`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "content-type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
        body: JSON.stringify({
            firstName:data.firstName,
            lastName:data.lastName,
            email: data.email,
            password: data.password
        })
      })
        .then((response) => response.json())
        .then(async (responseJson) => {
        //   console.log(responseJson)
          if(responseJson.status === "success"){
            alert("User added successfully");
            setBtnIsLoading(false);
          }
          if(responseJson.status === "error"){
            alert(responseJson.message);
            console.log(responseJson.message);
            setBtnIsLoading(false);
          }

        })
        .catch((error) => {
        console.error(error);
        })
    }



    return (<>

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">USERS</p>
                                        </div>

                                        
                                              
                                <div class="col-md-12 mt-2 mb-2">    
                                            <div class="row">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ul class="breadcrumb">
                                                                   <li class="" style={{backgroundColor: "#ffd200", color: "#000"}}>
                                                                        <a href=""> All</a>
                                                                    </li>                                                                    
                                                                </ul>
                                                            </div>
                                                            <div class="col-md-5 search">
                                                                <input class="form-control" type="text" placeholder="Search" aria-label="Search"/>
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                        
                                        <div className="col-md-12 mt-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <p className="title2">All Admin</p>
                                            </div>
                                            <div className="col-md-6">
                                              <p class="title2">
                                            <a href="" data-toggle="modal" data-target="#exampleModal"  style={{backgroundColor:"#ffd200", color:"#000", fontSize:"12px", padding:"5px 10px", borderRadius:"10px"}}> Add Admin</a>
                                              </p>
                                             
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12 mt-3">

                                                  <div className="table-responsive">
                                                    <table className="table white black-text  table-hover">
                                                      <thead>
                                                      </thead>
                                                      <tbody>
                                        {user.map(p => (
                                                <tr className="">
                                                  <td>{p.firstName} {p.lastName}</td>
                                                  <td>{p.email}</td>
                                                  {/* <td>
                                                    <Link to={`/User-Detials/${p._id}`}>
                                                      <i className="fas fa-pen" style={{color: "#000"}}></i>
                                                      </Link>
                                                  </td>
                                                  {/* <td>
                                                    <a href="">
                                                      <i className="fas fa-trash" style={{color: "red"}}></i>
                                                    </a>
                                                    </td> */}
                                                </tr>

                                            ))} 
                                                       </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>






                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>



     
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Add Admin</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                <div class="col-1"></div>
                                    <div class="col-10">
                                      <form onSubmit={handleSubmit(addAdmin)} class="">
                                        <div class="row">
                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="First Name" name="firstName" ref={register({ required: true, })}/>
                                                {errors.firstName && <p>First Name  is required </p>}
                                            </div>
                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="Last Name" name="lastName" ref={register({ required: true, })}/>
                                                {errors.lastName && <p>Last Name  is required </p>}
                                            </div>
                                            <div class="col-12">
                                                <input type="text" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="Email" name="email" ref={register({ required: true, })}/>
                                                {errors.email && <p>Email  is required </p>}
                                            </div>
                                            <div class="col-12">
                                                <input type="password" id="defaultRegisterFormFullName" class="mt-4 p-2" placeholder="Password" name="password" ref={register({ required: true, })}/>
                                                {errors.password && <p>Password  is required</p>}
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-warn my-4 btn-block waves-effect waves-light btn-sm" type="submit" name="add" disabled={btnisloading}>
                                              {btnisloading ? (<> Please Wait.. </>) : ( <> Add Product</>)}</button>
                                            </div>
                                        </div>
                                      </form>
                                    </div>
                                <div class="col-1"></div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>

    </>);

}

export default Admin;