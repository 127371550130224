import React, {useEffect, useState} from 'react';
import Sidebar from './Sidebar';
import {Link, useParams} from "react-router-dom";
import AddProduct from './components/AddProduct';
import configData from '../config.json';

const ProductDetails = () => {
    const [product, setProduct] = useState([]);
    const [btnisloading, setBtnIsLoading] = useState(false);
    const [image, setImage] = useState();
    const [name, setName] = useState();
    const [costPerUnit, setCostPerUnit] = useState();
    const [unit, setUnit] = useState();

    
    let { id } = useParams();


    const updateProduct = (e) => {
        setBtnIsLoading(true);
  
          e.preventDefault()
  
          const form = new FormData(document.getElementById("submit"))
  
            return fetch(`${configData.SERVER_URL}/products/${id}/update`, {
                  method: "post",
                  headers: {
                      Accept: "application/json",
                      "content-type": "application/json",
                      "x-api-token":  window.localStorage.getItem("token")
                  },
                  body:form
              })
              .then((response) => response.json())
              .then((responseJson) => {
  
                  if(responseJson.status === "success"){
  
                    setBtnIsLoading(false);
  
                      alert(responseJson.message);
                  }
                  if (responseJson.status === "error") {
                      setBtnIsLoading(false);
                      alert(responseJson.message);
                  }
              })
              .catch((error) => {
                  console.error(error);
              });
      }
  
      const agentFunc = async () => {
    
        return fetch(`${configData.SERVER_URL}/products`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token":  window.localStorage.getItem("token")
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
              console.log(responseJson);
              setProduct(responseJson.data);
          })
          .catch((error) => {
            console.error(error);
          });
      }

    useEffect(() => {
        agentFunc();
    }, [])


    return (
    
		<div className="wrapper d-flex align-items-stretch">
           
    <Sidebar />

    <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
    <div className="row">
                 <div className="col-md-12">
                     <div className="row">
                         
                         <div className="col-md-12" id="contentDash">
                             <div className="row">

                                 <div className="col-md-12 mt-2">
                                     <p className="title">Edit Product Details</p>
                                 </div>

                                 <div className="col-md-12 mt-4">
                                   <div className="row">
                                     <div className="col-md-12">
                                       <div className="row">
                                          <div class="col-md-7 mt-3">

                                          <form onSubmit={updateProduct} enctype="multipart/form-data" class="row" id="submit">
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlFile1">Upload Product Image Here</label>
                                                <input type="file" class="form-control-file" name="image" id="exampleFormControlFile1"/>
                                            </div>
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlInput1">Product Name</label>
                                                <input type="email" class="p-1" value={name} id="exampleFormControlInput1"  name="name"/>
                                            </div>
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlInput1">Product Price</label>
                                                <input type="text" class="p-1" value={costPerUnit} id="exampleFormControlInput1"  name="costPerUnit"/>
                                            </div>
                                            <div class="form-group col-md-10">
                                                <label for="exampleFormControlSelect1">Select Unit</label>
                                                <select class="form-control" value={unit} id="exampleFormControlSelect1" name="unit">
                                                
                                                {product.map(p => (
                                                        <option value={p.unit}>{p.unit}</option>

                                                      ))} 
                                                </select>
                                            </div>
                                            <button type="submit" class="btn btn-warn btn-block btn-sm waves-effect waves-light" name="submit" disabled={btnisloading}>
                                              {btnisloading ? (<> Please Wait.. </>) : ( <> Update</>)}
                                            </button>
                                            
                                            </form>
                                              
                                           </div>

                                          <div class="col-md-5 mt-3">  
                                                <AddProduct/> 
                                          </div>

                                       </div>
                                     </div>

                                   </div>
                                 </div>




                             </div>
                         </div>

                   



                         
                     </div>
                 </div>
             </div>
             
     </div>

   </div>


    );

}
export default ProductDetails;