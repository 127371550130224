import React, {useState, useEffect} from 'react';
import Sidebar from './Sidebar';
import configData from '../config.json';
import { format } from 'date-fns' 

const Agents = () => {

    
  const [agent, setAgent] = useState([]);

  const agentFunc = async () => {
  
      return fetch(`${configData.SERVER_URL}/agents`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson.data);
            setAgent(responseJson.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }

  useEffect(()=>{
      agentFunc();
  }, [])


  const AgentDet = async (id) => {
       await window.localStorage.setItem("agentId", id);
       window.location = window.location.origin + "/Agent-Detials"  

  }


    return (

         
		<div className="wrapper d-flex align-items-stretch">
           
           <Sidebar />

           <div id="content" className="pl-4 pb-4 pl-md-5 pt-5">
           <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">AGENTS</p>
                                        </div>

                                              
                                <div class="col-md-12 mt-2 mb-2">    
                                            <div class="row">
                                                    <div class="container">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <ul class="breadcrumb">
                                                                   <li class="" style={{backgroundColor: "#ffd200", color: "#000"}}>
                                                                        <a href=""> Approval</a>
                                                                    </li>
                                                                      <li class="li"> 
                                                                         <a href="pendingAgent">Pending Approval</a>
                                                                        </li>
                                                                        
                                                                </ul>
                                                            </div>
                                                            <div class="col-md-5 search">
                                                                <input class="form-control" type="text" placeholder="Search" aria-label="Search"/>
                                                            </div>
                                                            <div className="col-md-1"></div>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>

                                        
                                        <div className="col-md-12 mt-4">
                                          <div className="row">
                                            <div className="col-6">
                                              <p className="title2">All Staffs</p>
                                            </div>
                                            <div className="col-6">
                                              <p class="title2">
                                            <a href="" data-toggle="modal" data-target="#exampleModal"  style={{backgroundColor:"#ffd200", color:"#000", fontSize:"12px", padding:"5px 10px", borderRadius:"10px"}}> Add Agent</a>
                                              </p>
                                             
                                            </div>
                                            <div className="col-md-12">
                                              <div className="row">
                                                <div className="col-md-12 mt-3">

                                                  <div className="table-responsive">
                                                    <table className="table white black-text  table-hover">
                                                      <thead>
                                                      </thead>
                                                      <tbody>
                                                  {agent.map(p => (
                                                            <tr className="">
                                                              <td>{p.firstName} {p.lastName}</td>
                                                              <td>{p.phoneNumber}</td>
                                                              <td>{p.email}</td>
                                                              <td>{format(new Date(p.createdAt), 'MMMM, do, yyy')}</td>
                                                              <td>
                                                                {(p.approvalStatus === "approved") && <span style={{backgroundColor:'green', color:'#fff', padding:5, borderRadius:20, fontSize:12}}>Approved</span>}
                                                                {(p.approvalStatus === "pending") && <span style={{backgroundColor:'#ffd200', color:'#000', padding:5, borderRadius:20, fontSize:12}}>Pending</span>}
                                                                {(p.approvalStatus === "rejected") && <span style={{backgroundColor:'red', color:'#fff', padding:5, borderRadius:20, fontSize:12}}>Rejected</span>}
                                                              </td>
                                                              <td onClick={()=> AgentDet(p._id)}>
                                                                 <i className="fas fa-pen" style={{color: "#000"}}></i>
                                                              </td>
                                                            </tr>
                                                        ))}
                                                          
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </div>
                                            
                                            </div>
                                          </div>
                                        </div>




                                    </div>
                                </div>

                          



                                
                            </div>
                        </div>
                    </div>
                    
            </div>
   
          </div>


    );
  }

export default Agents;